<template>
  <SectionPanel
    :colour="colour"
    :title="title"
    :count="itemCount"
    :loading="loading"
  >
    <template #toolbar>
      <LayoutToolbar
        v-model="contentLayout"
        class="px-3"
        :items="items"
        :filters="itemFilters"
        :layouts="layouts"
        :sort="itemSortOptionName"
        :sorts="itemExtendedSortOptions"
        @results="itemResultSet = $event"
        @update:layout="updatePreferences({ favouritesLayout: $event })"
        @update:sort="updatePreferences({ favouritesSort: $event })"
      />
    </template>
    <CardGrid
      v-if="contentLayout === 'grid'"
      :items="resultSet"
    >
      <template #card="props">
        <!-- need .prevent to reverse default behaviour which is to ignore these events  -->
        <DraggableNode
          :active="isContentDraggable"
          drag-type="content"
          :identifier="props.item.id"
          @drop="moveFavourite({ from: $event.dragId, after: $event.dropId })"
        >
          <ContentCardSkeleton v-if="loading" />
          <ContentCard
            v-else
            :item="props.item"
            @view:item="$emit('view:item', props.item)"
            @view:parent="$emit('view:parent', props.item.parent)"
            @share="$emit('share', props.item)"
          />
        </DraggableNode>
      </template>
    </CardGrid>
    <CardCarousel
      v-else
      title="home.favourites"
      :items="resultSet"
      :card-width="300"
    >
      <template #card="props">
        <ContentCardSkeleton v-if="loading" />
        <ContentCard
          v-else
          :item="props.item"
          @view:item="$emit('view:item', props.item)"
          @view:parent="$emit('view:parent', props.item.parent)"
          @share="$emit('share', props.item)"
        />
      </template>
    </CardCarousel>
  </SectionPanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { dummyItems } from '@/components/content/dummyItems.js'

// content components
import ContentCard from '@/components/content/ContentCard'
import ContentCardSkeleton from '@/components/content/ContentCardSkeleton'

// base components
import CardCarousel from '@/components/base/CardCarousel'
import CardGrid from '@/components/base/CardGrid'
import DraggableNode from '@/components/base/DraggableNode'
import LayoutToolbar from '@/components/base/LayoutToolbar'
import SectionPanel from '@/components/base/SectionPanel'

import contentMixin from '@/components/content/contentMixin.js'
import mobileMixin from '@/mixins/mobileMixin.js'

export default {
  name: 'ContentPanel',

  components: {
    CardCarousel,
    CardGrid,
    ContentCard,
    ContentCardSkeleton,
    LayoutToolbar,
    DraggableNode,
    SectionPanel
  },

  mixins: [contentMixin, mobileMixin],

  props: {
    items: {
      type: Array,
      required: true
    },

    colour: {
      type: String,
      required: false,
      default: 'secondary'
    },

    layouts: {
      type: Array,
      required: false,
      default: () => ['carousel']
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    title: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {
      itemResultSet: [],
      contentLayout: 'carousel',
      skeletonItems: dummyItems(6)
    }
  },

  computed: {
    ...mapGetters('userStore', ['getPreference']),

    isContentDraggable() {
      // only allow dragging if all favourites are visible (unfiltered or excluded via search)
      // and the sort order is "as is"
      return (
        this.itemResultSet.length === this.items.length && this.itemSortOptionName === 'sortByAsIs'
      )
    },

    itemCount() {
      return this.itemResultSet.length
    },

    itemFilters() {
      return this.contentMixin_filters
    },

    itemExtendedSortOptions() {
      return [
        ...this.contentMixin_sorts,
        {
          text: this.$t('ui.sortOptions.sortByAsIs'),
          value: 'sortByAsIs'
        }
      ]
    },

    itemSortOptionName() {
      return this.getPreference('favouritesSort')
    },

    resultSet() {
      return this.loading ? this.skeletonItems : this.itemResultSet
    }
  },

  created: function () {
    this.contentLayout = this.mobileMixin_isReallyMobile
      ? 'carousel'
      : this.getPreference('favouritesLayout')
  },

  methods: {
    ...mapActions('userStore', ['updatePreferences', 'moveFavourite']),

    addFavourite(items, item) {
      return [...items, item]
    },

    removeFavourite(items, itemId) {
      return items.filter((item) => item.id !== itemId)
    }
  }
}
</script>

