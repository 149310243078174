<template>
  <div
    ref="progress"
    class="c-circular-progress"
  >
    <div
      class="c-rings d-flex align-center justify-center pa-4"
      :class="isRow ? 'flex-row' : 'flex-column'"
      :style="[ backgroundStyle ]"
    >
      <v-progress-circular
        :color="colours[2]"
        indeterminate
        :rotate="0"
        :size="ringSizes[2]"
        :width="5"
      >
        <v-progress-circular
          :color="colours[1]"
          indeterminate
          :rotate="90"
          :size="ringSizes[1]"
          :width="5"
        >
          <v-progress-circular
            :color="colours[0]"
            indeterminate
            :rotate="180"
            :size="ringSizes[0]"
            :width="5"
          >
            <div v-show="logoSize > 16">
              <v-img
                :src="orgLogo"
                class="c-logo"
                contain
                :height="logoSize"
                :width="logoSize"
              />
            </div>
          </v-progress-circular>
        </v-progress-circular>
      </v-progress-circular>
      <div v-if="message" class="text-h4 my-4 px-4">
        {{ message }}
      </div>
      <slot name="append" class="mt-4" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CircularProgress',

  components: {},

  props: {
    background: {
      type: String,
      required: false,
      default: ''
    },

    colours: {
      type: Array,
      required: false,
      // default: () => ['primary', 'secondary', 'accent']
      default: () => ['purple', 'orange', 'yellow accent-2']
    },

    logo: {
      type: String,
      required: false,
      default: ''
    },

    message: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {
      isRow: false,
      base: 0,
      logoSize: 0,
      ringSizes: [200, 175, 150]
    }
  },

  computed: {
    backgroundStyle() {
      return this.background
        ? { backgroundColor: this.background }
        : { backgroundColor: 'var(--v-background-base)' }
    },

    orgLogo() {
      return this.logo || this.$store.state.orgStore.orgLogo
    }
  },

  mounted: function () {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },

  beforeDestroy: function () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    calcHeight(factor) {
      const clamp = (min, num, max) => Math.min(Math.max(num, min), max)
      return clamp(factor * 10, this.base * factor, factor * 100)
    },

    onResize() {
      const height = this.$refs.progress.clientHeight
      this.base = 0.25 * height
      this.logoSize = this.calcHeight(1.5)
      this.ringSizes = [this.calcHeight(2.0), this.calcHeight(2.25), this.calcHeight(2.5)]
      this.isRow = height < 300
    }
  }
}
</script>

<style lang="css" scoped>
.c-circular-progress {
  height: 100%;
  width: 100%;
}

.c-rings {
  height: 100%;
  width: 100%;
}

.c-logo {
  border-radius: 50%;
}
</style>

