// import client from '@client'
import client from '@/services/real'

class PostService {
  constructor() {
    this.type = 'sanity'
  }

  fetchPosts = async ({ filter }) => {
    const posts = await client.fetchPosts({ filter })
    return posts
  }

  fetchPost = async ({ postId, postSlug }) => {
    const post = await client.fetchPost({ postId, postSlug })
    return post.id !== undefined ? post : null
  }

  incrementLikes = async ({ postId, deltaLikes }) => {
    const { id, likes } = await client.incrementLikes({ postId, deltaLikes })
    return { id, likes }
  }
}

export default PostService
export const postService = new PostService()
