import Vue from 'vue'
import Vuex from 'vuex'
import { libraryCollectionKey as LIBRARY_COLLECTION_KEY } from '@/config/appConfig'
import { contentService } from '@/services/contentService'

Vue.use(Vuex)

const initialState = {
  // persisted state
  collections: [],
  collectionItems: {},
  headers: [],
  taxonomyTitles: {},
  // transient state
  fetched: {}, // true if a succesful fetch occured in current session
  loading: {},
  promises: {},
  primed: false,
  // storage
  storage: 'indexDB'
}

export default {
  namespaced: true,

  state: () => initialState,

  getters: {
    activePortal(state, getters, rootState) {
      return rootState.portalStore.activePortal
    },

    getCollectionSpec(state) {
      return (collectionKey) =>
        state.collections.find((collection) => collection.key === collectionKey)
    },

    getCollectionTitle(state, getters) {
      return (collectionKey) =>
        collectionKey === LIBRARY_COLLECTION_KEY
          ? Vue.prototype.$t('menu.library.title')
          : getters.getCollectionSpec(collectionKey).title ||
            Vue.prototype.$t('menu.collection.title')
    },

    getCollectionItems(state) {
      return (collectionKey) => state.collectionItems[collectionKey] || []
    },

    getItem(state) {
      return ({ itemId, itemSlug, collectionName = LIBRARY_COLLECTION_KEY }) =>
        itemId
          ? state.collectionItems?.[collectionName].find((item) => item.id === itemId)
          : state.collectionItems?.[collectionName].find((item) => item.slug === itemSlug)
    },

    getItemsByAuthor(state) {
      return (authorId) =>
        state.collectionItems[LIBRARY_COLLECTION_KEY]?.find((item) => item.authorId === authorId)
    },

    getItemsByCollection(state) {
      return (collectionName) =>
        collectionName in state.collectionItems ? state.collectionItems[collectionName] : []
    },

    isCollectionLoading(state) {
      return (key) => (key in state.loading ? state.loading[key] : false)
    },

    isCollectionPrimed(state) {
      return (key) => {
        const hasItems = key in state.collectionItems && state.collectionItems[key].length > 0
        const wasFetched = key in state.fetched && state.fetched[key]
        return hasItems || wasFetched
      }
    },

    isLibraryLoading(state, getters) {
      return getters.isCollectionLoading(LIBRARY_COLLECTION_KEY)
    },

    isLibraryPrimed(state, getters) {
      return getters.isCollectionPrimed(LIBRARY_COLLECTION_KEY)
    },

    libraryItems(state) {
      return state.collectionItems[LIBRARY_COLLECTION_KEY] || []
    }
  },

  mutations: {
    /* common mutations */

    syncState(state, newState) {
      state.headers = [...newState.headers]
      state.collections = [...newState.collections]

      // prime the library collection first
      if (LIBRARY_COLLECTION_KEY in state.collectionItems) {
        state.collectionItems[LIBRARY_COLLECTION_KEY] =
          newState.collectionItems[LIBRARY_COLLECTION_KEY] || []
      } else {
        state.collectionItems = {
          ...state.collectionItems,
          [LIBRARY_COLLECTION_KEY]: newState.collectionItems[LIBRARY_COLLECTION_KEY] || []
        }
      }

      if (state.collectionItems[LIBRARY_COLLECTION_KEY]?.length > 0) {
        state.primed = true
      }

      // prime the remaining collections

      // eslint-disable-next-line no-unused-vars
      const { [LIBRARY_COLLECTION_KEY]: _libraryItems, ...collections } = newState.collectionItems
      for (const collectionName in collections) {
        if (collectionName in state.collectionItems) {
          state.collectionItems[collectionName] = newState.collectionItems[collectionName] || []
        } else {
          state.collectionItems = {
            ...state.collectionItems,
            [collectionName]: newState.collectionItems[collectionName] || []
          }
        }
      }

      // update the custom taxonomy titles
      state.taxonomyTitles = { ...newState.taxonomyTitles }

      // note: promises, loading are not refreshed with session state
    },

    /* specific mutations */

    setCollectionItems(state, { key, items }) {
      key in state.collectionItems
        ? (state.collectionItems[key] = items)
        : (state.collectionItems = {
            ...state.collectionItems,
            [key]: items
          })
    },

    setCollectionItemsSet(state, collectionItemsSet) {
      state.collectionItems = {
        ...state.collectionItems,
        ...collectionItemsSet
      }
    },

    setCollections(state, collections) {
      state.collections = collections
    },

    setHeaders(state, headers) {
      state.headers = headers
    },

    setFetched(state, { key, fetched }) {
      // update promise
      key in state.fetched
        ? (state.fetched[key] = fetched)
        : (state.fetched = {
            ...state.fetched,
            [key]: fetched
          })
    },

    setLoading(state, { key, loading, promise = null }) {
      if (key in state.loading) {
        state.loading[key] = loading
        state.promises[key] = promise
      } else {
        state.loading = {
          ...state.loading,
          [key]: loading
        }
        state.promises = {
          ...state.promises,
          [key]: promise
        }
      }
    },

    setPrimed(state, isPrimed) {
      state.primed = isPrimed
    },

    setTaxonomyTitles(state, taxonomyTitles) {
      state.taxonomyTitles = taxonomyTitles
    },

    syncCollection(state, collection) {
      const index = state.collections.findIndex((c) => c.key === collection.key)

      index < 0
        ? state.collections.push(collection) // append
        : state.collections.splice(index, 1, collection) // replace
    }
  },

  actions: {
    /* common actions */

    restoreState({ state, commit }, { sessionState }) {
      commit('syncState', { ...state, ...sessionState })
    },

    /* fetch actions */

    async fetchLibrary({ state, commit, dispatch }) {
      // set headers
      if (state.headers.length < 1) {
        const headers = contentService.getContentHeaders()
        if (headers.length > 0) commit('setHeaders', headers)
      }

      // fetch all items
      await dispatch('fetchCollectionItems', { key: LIBRARY_COLLECTION_KEY })
    },

    async fetchCollectionItems({ state, commit, getters }, { key }) {
      // check if the collection has not yet been fetched in this session
      if (!(key in state.fetched)) {
        // needs a refresh from the CMS
        if (!(key in state.loading) || !state.loading[key]) {
          // first one in turns on the lights
          const filter = getters.activePortal?.contentFilter
          try {
            console.warn(`[contentStore]: Fetching collection '${key}' from CMS.`)
            const promise = contentService.fetchContent({ filter, collectionKey: key })
            commit('setLoading', { key, loading: true, promise })
            const items = await promise
            commit('setCollectionItems', { key, items })
            commit('setFetched', { key, fetched: true })
            return items
          } catch (error) {
            console.error(`[contentStore]: ...Fetch failed for key '${key}'. Using cache.`, error)
            return state.collectionItems[key] || []
          } finally {
            commit('setLoading', { key, loading: false })
            if (key === LIBRARY_COLLECTION_KEY) commit('setPrimed', true)
            // else dispatch('syncCollection', { collections })
          }
        } else {
          // a close second request delegates back to the first request to do all the work
          try {
            console.warn(`[contentStore]: Use pre-existing promise for key '${key}'.`)
            if (key in state.collectionItems) {
              // return the cache value
              return state.collectionItems[key]
            } else {
              // wait because there's no cached content to display
              const items = await state.promises[key]
              return items
            }
          } catch (error) {
            console.warn(`[contentStore]: ...Pre-existing fetch failed for key '${key}'.`, error)
            return state.collectionItems[key] || []
          }
        }
      } else {
        // use cached values (already fetched)
        const count = state.collectionItems[key].length
        console.warn(`[contentStore]: Fetching collection '${key}' from cache (${count}).`)
        return state.collectionItems[key]
      }
    },

    async fetchItem({ getters }, { itemId, itemSlug }) {
      const item = getters.getItem({ itemId, itemSlug })

      if (item) {
        return item
      } else {
        try {
          const fetchedItem = await contentService.fetchItem({ itemId, itemSlug })
          return fetchedItem
        } catch (error) {
          console.error('[contentStore]: Error fetching item.', error)
          return null
        }
      }
    },

    /* specific actions */

    addCollection({ state, commit }, { collection }) {
      // check if the collection is already in the state
      const index = state.collections.findIndex((c) => c.key === collection.key)

      // add/replace the collection
      const collections = [...state.collections]
      index < 0
        ? collections.push(collection) // append
        : collections.splice(index, 1, collection) // replace
      commit('setCollections', collections)
    },

    updateCollections({ commit }, { collections }) {
      commit('setCollections', collections || [])
    },

    updateCollectionItems({ commit }, { key, items }) {
      commit('setCollectionItems', { key, items })
    },

    updateTaxonomyTitles({ commit }, { taxonomyTitles }) {
      commit('setTaxonomyTitles', taxonomyTitles)
    }
  }
}
