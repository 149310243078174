// import client from '@client'
import client from '@/services/real'
class ContentService {
  constructor() {
    this.type = 'sanity'
  }

  fetchContent = async ({ filter, collectionKey }) => {
    const items = await client.fetchContent({ filter, collectionKey })
    return items
  }

  fetchContentItem = async (id) => {
    const item = await client.fetchContentItem(id)
    return item
  }

  fetchContentItemBySlug = async (slug) => {
    const item = await client.fetchContentItem(slug)
    return item
  }

  getContentHeaders = () => {
    return client.getContentHeaders()
  }

  fetchModel = async (path) => {
    const model = await client.fetchModel(path)
    return model
  }
}

export default ContentService
export const contentService = new ContentService()
