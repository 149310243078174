export default {
  activePortal(state, getters, rootState) {
    return rootState.portalStore.activePortal
  },

  arePostsPrimed(state) {
    return state.primed
  },

  arePostsLoading(state) {
    return state.loading == undefined ? false : state.loading
  },

  getPostsByAuthor(state) {
    return ({ id, lastName }) =>
      id
        ? state.posts.find((post) => post.author?.id === id)
        : state.posts.find((post) => post.author?.lastName === lastName)
  },

  getPost(state) {
    return ({ postId, postSlug }) =>
      postId
        ? state.posts.find((post) => post.id === postId)
        : state.posts.find((post) => post.slug === postSlug)
  },

  getLikes(state, getters) {
    return (postId) => getters.getPost({ postId })?.likes || 0
  }
}
