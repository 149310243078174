import store from '@/store'

export const fetchPosts = async ({ filter = null }) => {
  const tenantKey = store.state.tenantStore.tenantKey
  const queryString = filter ? `?filter=${encodeURIComponent(filter)}` : ''

  try {
    const response = await fetch(`/api/tenant/${tenantKey}/posts${queryString}`)

    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[postService]:', error)
    throw error
  }
}

export const fetchPost = async ({ postId, postSlug }) => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const response = postId
      ? await fetch(`/api/tenant/${tenantKey}/post/${postId}`)
      : await fetch(`/api/tenant/${tenantKey}/post/slug/${postSlug}`)

    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[postService]:', error)
    return {}
  }
}

export const incrementLikes = async ({ postId, deltaLikes }) => {
  const tenantKey = store.state.tenantStore.tenantKey

  try {
    const request = {
      method: 'POST'
    }
    const queryParams = deltaLikes ? `?inc=${deltaLikes}` : ''
    const response = await fetch(
      `/api/tenant/${tenantKey}/post/${postId}/likes${queryParams}`,
      request
    )
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  } catch (error) {
    console.error('[postService]:', error)
    return {
      message: error
    }
  }
}
